import React from "react";
import Particles from "react-tsparticles";

function Particle() {
  return (
    <div></div>
  )
}

export default Particle;
